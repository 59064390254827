import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Box from '@material-ui/core/Box';

import Search from "./search"

const SubpageHeader = () => (
  <StaticQuery
    query={graphql`
      query SubpageSearchIndexQuery {
        siteSearchIndex {
          index
        }
      }
    `}
    render={data => (
      <Box
				component="header"
				display="flex"
      	flexWrap="nowrap"
				style={{
					padding: '15px 0',
					overflow: 'visible',
					height: '82px'
				}}
			>
				<Box component="div" display="inline" alignItems="left" width="20%" style={{
					//float:'left',
					//width: '20%',
					lineHeight: '52px'
				}}>
					<Link to="/" style={{textDecoration:'none'}}>
						<img src="/limecoda.png" alt="LimeCoda" style={{marginBottom:"0",display:"inline-block",verticalAlign:'middle'}} />
					</Link>
				</Box>
				<Box component="div" display="inline" alignItems="right" ml="4%" width="76%" style={{
					//width: '76%',
					//float:'right'
				}}>
        	<Search searchIndex={data.siteSearchIndex.index} />
				</Box>
      </Box>
    )}
  />
)

export default SubpageHeader