import React from "react"
import { Link, graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Layout from "../components/layout"
import SubpageHeader from "../components/subpageHeader";
import SEO from "../components/seo"

const useStyles = makeStyles({
  root: {
    minWidth: 275,
		marginBottom: '20px'
  },
	postTitle: {
		color: '#084400',
		fontWeight: '500',
		fontSize: '1.9rem'
	},
  pos: {
    marginBottom: 12,
		fontSize: '0.75rem'
  },
	articleContainer: {
		padding: '20px',
		margin: '30px 0'
	}
});

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

	const classes = useStyles();

  return (
    <Layout location={location} title={siteTitle}>
			<SubpageHeader />
			<Paper className={classes.articleContainer} variant="outlined">
      	<SEO
      	  title={post.frontmatter.title}
      	  description={post.frontmatter.description || post.excerpt}
      	/>
      	<article>
      	  <header style={{
						padding: '0 0 13px'
					}}>
      	    <Typography className={classes.postTitle} variant="h1" component="h1">
      	      {post.frontmatter.title}
      	    </Typography>
      	    <Typography className={classes.postBody} component="p">
      	      {post.frontmatter.date}
      	    </Typography>
      	  </header>
      	  <section class="post-body" dangerouslySetInnerHTML={{ __html: post.html }} />
      	  <footer>
      	  </footer>
      	</article>
			</Paper>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
